<template>
  <div>
    <b-row>
      <b-col cols="3">
        <OrdersList
          v-bind:orders="orders"
          v-bind:page="page"
          v-bind:page-size="pageSize"
          v-bind:total="filtered"

          v-on:page="onPageChanged"
          v-on:search="onSearch"
          v-on:select="onSelected"
        />
      </b-col>

      <b-col cols="9">
        <OrderDetails
          v-bind:order="details"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import lodash from "lodash";

import api from "@/core/services/api";

import OrdersList   from "@/view/pages/orders/List.vue";
import OrderDetails from "@/view/pages/orders/Details.vue";


const pageSize = 5;

function mapOrderSummary(order) {
  const mapped = {
    ...order,

    selected: false,
  };

  if (mapped.multiple) {
    mapped.multiple.index++;
  }

  return mapped;
}

function mapOrderDetails(order) {
  const mapped = {
    ...order,
  };

  if (mapped.history) {
    // Add current state to top of history.
    mapped.history.splice(0, 0, mapOrderToHistory(order));

    // Fix timestamp of entries [2..n-1].
    for (let i = 1; i < mapped.history.length - 1; i++) {
      mapped.history[i].timestamp = mapped.history[i + 1].timestamp;
    }

    // Fix timestamp of "new" state entry.
    mapped.history.at(-1).timestamp = order.created;
  }

  if (mapped.multiple) {
    mapped.multiple.index++;
  }

  return mapped;
}

function mapOrderToHistory(order) {
  const mapped = lodash.pick(order, [
    "status", "reason", "duration",
    "bidding", "car", "station"
  ]);

  const ts = lodash.defaults(
    { timestamp: order.cancelled },
    { timestamp: order.completed },
    { timestamp: order.confirmed },
    { timestamp: order.arrived  },
    { timestamp: order.dispatchAt },
    { timestamp: order.stationAt },
    { timestamp: order.biddingAt },
    { timestamp: order.created }
  );

  mapped.timestamp = ts.timestamp;

  return mapped;
}

export default {
  name: "Orders",

  components: {
    OrdersList,
    OrderDetails,
  },

  data() {
    return {
      orders: [],
      details: null,

      page: 1,
      pageSize,
      total: 0,
      filtered: 0,

      search: "",
    };
  },

  computed: {
    start() {
      return (this.page - 1) * pageSize;
    },
  },

  async mounted() {
    this.$store.dispatch("setBreadcrumb", [{ title: "Comenzi" }]);

    await this.loadOrders();
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** List Events ***** ***** ***** ***** ***** \*/
    async onPageChanged(page) {
      this.page = page;

      await this.loadOrders();
    },

    async onSearch(search) {
      this.page   = 1;
      this.search = search;

      await this.loadOrders();
    },

    async onSelected(order) {
      this.orders.forEach(o => o.selected = false);
      order.selected = true;

      await this.loadOrderDetails(order.id);
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    async loadOrders() {
      const orders = await api.orders.list(this.start, pageSize, this.search);
      const mapped = orders.data.map(mapOrderSummary);

      this.orders   = mapped;
      this.total    = orders.recordsTotal;
      this.filtered = orders.recordsFiltered;
    },

    async loadOrderDetails(id) {
      const order  = await api.orders.get(id);
      const mapped = mapOrderDetails(order)

      this.details = mapped;
    },
  },
};
</script>
