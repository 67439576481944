<template>
  <div class="timeline timeline-6">
    <div
      class="timeline-item align-items-start"
      v-for="(history, index) in order.history"
      v-bind:key="index"
    >
      <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">
        {{ history.timestamp | time }}
      </div>

      <div class="timeline-badge">
        <i v-if="history.status === 'new'" class="fa fa-genderless text-primary icon-xl" />
        <i v-if="history.status === 'bidding' && history.bidding.zoneId" class="fa fa-genderless text-info icon-xl" />
        <i v-if="history.status === 'bidding' && history.bidding.iteration == 1" class="fa fa-genderless text-info icon-xl" />
        <i v-if="history.status === 'bidding' && history.bidding.iteration >= 2" class="fa fa-genderless text-warning icon-xl" />
        <i v-if="history.status === 'station'" class="fa fa-genderless text-info icon-xl" />
        <i v-if="history.status === 'dispatch'" class="fa fa-genderless text-warning icon-xl" />
        <i v-if="history.status === 'confirmed'" class="fa fa-genderless text-success icon-xl" />
        <i v-if="history.status === 'arrived'" class="fa fa-genderless text-info icon-xl" />
        <i v-if="history.status === 'completed'" class="fa fa-genderless text-primary icon-xl" />
        <i v-if="history.status === 'cancelled'" class="fa fa-genderless text-danger icon-xl" />
      </div>

      <div class="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3">
        <template v-if="history.status === 'new'">
          Comandă plasată prin
          <span v-if="isCustomerFlow" class="text-primary">aplicație</span>
          <span v-if="isDispatchFlow" class="text-warning">dispecerat</span>
        </template>

        <template v-if="history.status === 'bidding'">
          <span v-if="!history.bidding.zoneId">Licitație</span>

          <span v-if="history.bidding.zoneId">Zona</span>
          <span v-if="history.bidding.zoneName" class="text-info">&nbsp; {{ history.bidding.zoneName }}</span>

          <span class="text-muted"> | </span>

          <span class="text-muted">
            <i class="fas fa-map-marker-alt" />
            {{ history.bidding.radius }} km
          </span>

          <span class="text-muted">
            <i class="fas fa-clock" />
            {{ history.bidding.duration | seconds }} sec
          </span>

          <span class="text-muted">
            <i class="fas fa-taxi" />
            {{ history.bidding.cars }}
          </span>

          <span class="text-muted">
            <i class="fas fa-star" />
            {{ history.bidding.bids }}
          </span>
        </template>

        <template v-if="history.status === 'station'">
          Stația
          <span class="text-info">{{ history.station.name }}</span>

          <span class="text-muted"> | </span>

          <span class="text-muted">
            <i class="fas fa-clock" />
            {{ history.station.duration | seconds }} sec
          </span>

          <span class="text-muted">
            <i class="fas fa-taxi" />
            {{ history.station.cars }}
          </span>

          <span class="text-muted">
            <i class="fas fa-star" />
            {{ history.station.bids }}
          </span>
        </template>

        <template v-if="history.status === 'dispatch'">
          Dispecerat
        </template>

        <template v-if="history.status === 'confirmed'">
          Confirmată

          <span class="text-muted"> | </span>

          <span class="text-success">
            <i class="fas fa-taxi text-success" />
            {{ history.car.id }}

            <i class="fas fa-clock text-success" />
            {{ history.duration | minutes }} min
          </span>
        </template>

        <template v-if="history.status === 'arrived'">
          Sosit la adresă
        </template>

        <template v-if="history.status === 'completed'">
          Finalizată
        </template>

        <template v-if="history.status === 'cancelled'">
          Anulată: {{ history.reason | reason }}
        </template>

        <!-- TODO: Display current status (not in history) -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const cancellationReasons = {
  addressIncomplete: "adresă insuficientă",

  carNotFound: "nicio mașină disponibilă",

  cancelledByCustomer: "anulată de către client",
  cancelledByDispatch: "anulată de către dispecer",

  customerSuspended:    "clientul a fost blocat",
  customerDidNotArrive: "clientul nu a coborât la mașină",

  driverDidNotComplete: "șoferul nu a finalizat comanda",
};

export default {
  filters: {
    reason: reason => cancellationReasons[reason],

    time: timestamp => moment(timestamp).format("HH:mm:ss"),

    duration: duration  => moment.duration(duration).humanize(),
    seconds:  duration  => moment.duration(duration).asSeconds(),
    minutes:  duration  => moment.duration(duration).asMinutes(),
  },

  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return { };
  },

  computed: {
    isCustomerFlow() {
      if (!this.order) return false;

      return this.order.flow === "customerModern" ||
             this.order.flow === "customerLegacy";
    },

    isDispatchFlow() {
      return this.order && this.order.flow === "dispatch";
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  &:before {
    left: 66px;
  }
  .timeline-item {
    .timeline-label {
      width: 65px;
    }
  }
}
</style>
