<template>
  <div
    class="order d-flex align-items-center rounded"
    v-bind:class="[background, order.status, { selected: isSelected }]"
  >
    <span
      v-if="showMultiple"
      class="order-multiple ml-0 mr-4"
      v-bind:class="`text-${color}`"
    >
      {{ order.multiple.index }} / {{ order.multiple.count }}
    </span>

    <div class="order-details d-flex flex-column flex-grow-1">
      <div class="order-timestamp">
        {{ order.created | weekDayTime }}
      </div>

      <div
        class="order-address"
        v-ellipsis-tooltip
        v-b-tooltip.hover="{ customClass: 'order-address-tooltip' }"
        v-bind:title="order.address | address"
      >
        {{ order.address | address }}
      </div>

      <div class="d-flex flex-row">
        <div class="order-customer">
          {{ order.customer.name }} | {{ order.customer.phone | phone }}
        </div>
        <div class="order-notes">{{ notes }}</div>
      </div>

      <div class="d-flex flex-row">
        <div v-if="showConfirmed" class="mr-5">
          <div class="order-car">
            <span class="order-car-id">
              <i class="fas fa-taxi" />

              {{ order.car.id }}
            </span>

            <!-- <span v-if="order.car.driver" class="order-driver">| {{ order.car.driver }}</span> -->
            <span class="order-duration">| {{ order.duration | duration }}</span>
          </div>
        </div>

        <div v-if="showCancelled" class="mr-5">
          <div class="order-reason">
            <i class="fas fa-exclamation-circle" />

            {{ cancellationReason }}
          </div>
        </div>

        <div v-if="order.dispatch && order.dispatch.name">
          <div class="order-dispatch">
            <i class="fas fa-id-badge" />

            {{ order.dispatch.name }}
          </div>
        </div>
      </div>
    </div>

    <a
      class="order-select btn btn-link-primary"
      v-on:click.stop="select"
      v-bind:class="{ disabled: isSelected }"
    >
      <i class="fas fa-share-square" />
    </a>
  </div>
</template>

<script>
import moment from "moment";
import { isNullOrEmpty, formatPhoneNumber, formatAddress } from "@/core/utils";

const statusColors = {
  new:      "warning",
  bidding:  "warning",
  station:  "warning",
  dispatch: "warning",

  arrived:   "success",
  confirmed: "success",
  completed: "success",

  cancelled: "danger",
};

const cancellationReasons = {
  addressIncomplete: "adresă insuficientă",

  carNotFound: "nicio mașină disponibilă",

  cancelledByCustomer: "anulată de către client",
  cancelledByDispatch: "anulată de către dispecer",

  customerSuspended:    "clientul a fost blocat",
  customerDidNotArrive: "clientul nu a coborât la mașină",

  driverDidNotComplete: "șoferul nu a finalizat comanda",
};

export default {
  directives: {
    ellipsisTooltip: {
      inserted: element => {
        const tolerance  = 2; // px
        const ellipsized = element.offsetWidth + tolerance < element.scrollWidth;

        if (!ellipsized) {
          element.removeAttribute("title");
          element.setAttribute("disabled", "");
        }
      },
    },
  },

  filters: {
    address:  formatAddress,
    phone:    formatPhoneNumber,

    duration:    duration  => moment.duration(duration).humanize(),
    weekDayTime: timestamp => moment(timestamp).format('dddd, D MMMM Y [ora] HH:mm:ss'),
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      color: statusColors[this.order.status],

      cancellationReason: cancellationReasons[this.order.reason],
    };
  },

  computed: {
    background() {
      if (this.order.selected) {
        return "bg-primary-o-50";
      } else {
        return "bg-light";
      }
    },

    notes() {
      const notes    = this.order.notes;
      const landmark = this.order.address.landmark;

      const hasNotes    = !isNullOrEmpty(notes);
      const hasLandmark = !isNullOrEmpty(landmark);

      if (hasLandmark && hasNotes) {
        return `${landmark}; ${notes}`;
      } else if (hasLandmark) {
        return landmark;
      } else if (hasNotes) {
        return notes;
      } else {
        return null;
      }
    },

    isSelected() {
      return this.order.selected;
    },

    isSent() {
      return this.order.status === "new"     ||
             this.order.status === "bidding" ||
             this.order.status === "station" ||
             this.order.status === "dispatch";
    },

    isCancelled() {
      return this.order.status === "cancelled";
    },

    isConfirmed() {
      return this.order.status === "arrived"   ||
             this.order.status === "confirmed" ||
             this.order.status === "completed";
    },

    isMultiple() {
      return !!this.order.multiple;
    },

    showCancelled() {
      return this.isCancelled;
    },

    showConfirmed() {
      return this.isConfirmed;
    },

    showMultiple() {
      return this.isMultiple;
    },
  },

  methods: {
    select() {
      this.$emit("select", this.order);
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }

  .order-details {
    min-width: 0;

    .order-timestamp {
      color: $dark-75;
      font-size: $font-size-sm;
      margin-bottom: 0.25rem;
    }

    .order-address {
      color: $dark-75;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      margin-bottom: 0.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .order-notes {
      color: $info;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .order-customer {
      color: $primary;
      margin-right: 1.25rem;
      margin-bottom: 0.25rem;
    }

    .order-car {
      font-weight: $font-weight-bold;

      .order-car-id {
        color: $info;

        i {
          color: $info;
        }
      }

      .order-driver {
        color: $text-muted;
        margin-right: 0.25rem;
      }

      .order-duration {
        color: $info;
      }
    }

    .order-reason {
      color: $danger;

      i {
        color: $danger;
        position: relative;
        top: 0.125rem;
      }
    }
  }

  .order-dispatch {
    color: $primary;

    i {
      color: $primary;
      position: relative;
      top: 0.075rem;
    }
  }

  .order-multiple {
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    margin-left: 0.5rem;
  }

  .order-select {
    margin-left: 1rem;

    i {
      font-size: 2rem;
    }
  }
}
</style>

<style lang="scss">
.order-address-tooltip {
  max-width: 20%;

  .tooltip-inner {
    max-width: 100%;
  }
}
</style>
