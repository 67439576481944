<template>
  <b-skeleton-wrapper v-bind:loading="order == null">
    <template v-slot:loading>
      <b-card>
        <b-skeleton width="85%" />
        <b-skeleton width="55%" />
        <b-skeleton width="70%" />

        <b-skeleton-table
          v-bind:rows="5"
          v-bind:columns="4"
          v-bind:table-props="{ bordered: false, striped: true }"
        />
      </b-card>
    </template>

    <div id="order-details" v-if="order">
      <b-card class="card-custom gutter-b" no-body>
        <b-card-header class="border-bottom-0">
          <b-card-title class="card-title font-weight-bolder">
            <span>
              Comanda
              <span class="text-muted">#{{ order.id }}</span>
              la
              <span class="text-primary">{{ order.address | address }}</span>

              <span v-if="order.notes">
                (obs. <span class="text-info">{{ order.notes }}</span>)
              </span>

              de la
              <span class="text-primary">{{ order.customer.name }}</span>
              (tel. <span class="text-info">{{ order.customer.phone | phone }}</span>)
            </span>
          </b-card-title>

          <div class="card-toolbar" />
        </b-card-header>
      </b-card>

      <b-row>
        <b-col cols="8">
          <b-card class="card-custom gutter-b">
            <b-skeleton width="85%" />
            <b-skeleton width="55%" />
            <b-skeleton width="70%" />
            <b-skeleton width="45%" />
            <b-skeleton width="70%" />
            <b-skeleton width="55%" />
            <b-skeleton width="85%" />
          </b-card>

          <b-card class="card-custom gutter-b">
            <b-skeleton-table
              v-bind:rows="5"
              v-bind:columns="2"
              v-bind:table-props="{ borderless: true }"
            />
          </b-card>
        </b-col>

        <b-col>
          <b-card v-if="isConfirmed || isArrived || isCompleted" class="card-custom gutter-b">
            <OrderProgress v-bind:order="order" />
          </b-card>

          <b-card class="card card-custom gutter-b" no-body>
            <b-card-header class="border-0 mt-4">
              <b-card-title>Istoric</b-card-title>
            </b-card-header>

            <b-card-body>
              <OrderTimeline v-bind:order="order" />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import moment from "moment";
import { isNullOrEmpty, formatPhoneNumber, formatAddress } from "@/core/utils";

const statusColors = {
  new:      "warning",
  bidding:  "warning",
  station:  "warning",
  dispatch: "warning",

  arrived:   "success",
  confirmed: "success",
  completed: "success",

  cancelled: "danger",
};

const cancellationReasons = {
  addressIncomplete: "adresă insuficientă",

  carNotFound: "nicio mașină disponibilă",

  cancelledByCustomer: "anulată de către client",
  cancelledByDispatch: "anulată de către dispecer",

  customerSuspended:    "clientul a fost blocat",
  customerDidNotArrive: "clientul nu a coborât la mașină",

  driverDidNotComplete: "șoferul nu a finalizat comanda",
};

import OrderProgress from "./details/OrderProgress.vue";
import OrderTimeline from "./details/OrderTimeline.vue";

export default {
  components: {
    OrderProgress,
    OrderTimeline,
  },

  directives: {
    ellipsisTooltip: {
      inserted: element => {
        const tolerance  = 2; // px
        const ellipsized = element.offsetWidth + tolerance < element.scrollWidth;

        if (!ellipsized) {
          element.removeAttribute("title");
          element.setAttribute("disabled", "");
        }
      },
    },
  },

  filters: {
    address:  formatAddress,
    phone:    formatPhoneNumber,

    reason: reason => cancellationReasons[reason],

    time: timestamp => moment(timestamp).format("HH:mm"),

    duration: duration  => moment.duration(duration).humanize(),
    seconds:  duration  => moment.duration(duration).asSeconds(),
    minutes:  duration  => moment.duration(duration).asMinutes(),
  },

  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return { };
  },

  computed: {
    color() {
      if (this.order) {
        return statusColors[this.order.status];
      } else {
        return null;
      }
    },

    cancellationReason() {
      if (this.order) {
        return cancellationReasons[this.order.reason];
      } else {
        return null;
      }
    },

    isSent() {
      return this.isNew       ||
             this.isInBidding ||
             this.isAtStation ||
             this.isAtDispatch;
    },

    isNew() {
      return this.order && this.order.status === "new";
    },

    isInBidding() {
      return this.order && this.order.status === "bidding"
    },

    isAtStation() {
      return this.order && this.order.status === "station";
    },

    isAtDispatch() {
      return this.order && this.order.status === "dispatch";
    },

    isArrived() {
      return this.order && this.order.status === "arrived";
    },

    isConfirmed() {
      return this.order && this.order.status === "confirmed";
    },

    isCompleted() {
      return this.order && this.order.status === "completed";
    },

    isCancelled() {
      return this.order && this.order.status === "cancelled";
    },

    isMultiple() {
      return this.order && !!this.order.multiple;
    },

    isCustomerFlow() {
      if (!this.order) return false;

      return this.order.flow === "customerModern" ||
             this.order.flow === "customerLegacy";
    },

    isDispatchFlow() {
      return this.order && this.order.flow === "dispatch";
    },
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** List Events ***** ***** ***** ***** ***** \*/

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/

  },
};
</script>
