<template>
  <div id="orders-list" class="card card-custom card-stretch">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder text-dark">Comenzi</h3>

      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <div role="group" class="input-group input-group-sm">
            <b-input-group size="sm">
              <b-input-group-prepend v-bind:is-text="true">
                <i class="fas fa-search" />
              </b-input-group-prepend>

              <b-form-input class="form-control" size="sm" placeholder="adresă, indicativ, telefon" v-model="search" />
            </b-input-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <Order
        v-for="order in orders"
        v-bind:key="order.id"
        v-bind:order="order"
        v-on="$listeners"
      />
    </div>

    <div class="card-footer">
      <b-pagination
        first-number
        hide-goto-end-buttons
        align="fill"
        size="lg"
        v-bind:value="page"
        v-bind:total-rows="total"
        v-bind:per-page="pageSize"
        v-on:change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import Order from "@/view/pages/orders/Summary.vue";

export default {
  components: {
    Order,
  },

  props: {
    orders: {
      type: Array,
      required: true,
    },

    page: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  watch: {
    search(value) {
      this.$emit("search", value);
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("page", page);
    },
  },
};
</script>

<style lang="scss">
#orders-list {
  .card-header {
    border: 0;
  }

  .card-body {
    padding: 1rem 1rem 1rem 2.25rem;
  }

  .card-footer {
    padding: 1rem;

    ul {
      margin-bottom: 0;
    }
  }
}
</style>
