<template>
  <Progress
    v-bind:variant="progressColor"
    v-bind:value="progressValue"
    v-bind:max="progressMax"
  >
    <template v-slot:left>
      <div class="text-muted mr-2 font-size-sm font-weight-bold">
        <span class="text-primary">Licitat: {{ duration | duration }}</span>
        |
        <span class="text-dark">Trecut: {{ elapsed | duration }}</span>
        |
        <span class="text-danger" v-if="late.asSeconds() > 0">Întărziat: {{ late | duration }}</span>
        <template v-else>
          <span class="text-success" v-if="remaining.asSeconds() > 60">Rămas: {{ remaining | duration }}</span>
          <span class="text-warning" v-else>Rămas: {{ remaining | duration }}</span>
        </template>
      </div>
    </template>
  </Progress>
</template>

<script>
import moment from "moment";

import Progress from "@/view/content/widgets/Progress.vue";

export default {
  components: { Progress },

  filters: {
    duration: duration  => moment.duration(duration).humanize(),
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return { };
  },

  computed: {
    created() {
      return moment(this.order.created);
    },

    confirmed(){
      return moment(this.order.confirmed);
    },

    arrived() {
      return this.order.arrived ? moment(this.order.arrived) : null;
    },

    reference() {
      if (this.order.arrived) {
        return moment(this.order.arrived);
      } else {
        return moment();
      }
    },

    duration() {
      return moment.duration(this.order.duration);
    },

    expected() {
      return this.confirmed.clone().add(this.duration);
    },

    elapsed() {
      return moment.duration(this.reference.diff(this.confirmed));
    },

    remaining() {
      let remaining = moment.duration(this.expected.diff(this.reference));
      if (remaining.asMilliseconds() < 0)
        remaining = moment.duration(0);

      return remaining;
    },

    late() {
      let late = this.elapsed.clone().subtract(this.duration);
      if (late.asMilliseconds() < 0)
        late = moment.duration(0);

      return late;
    },

    progressColor() {
      if (this.remaining.asMinutes() <= 0) {
        return "danger";
      } else if (this.remaining.asMinutes() < 1) {
        return "warning";
      } else {
        return "primary";
      }
    },

    progressValue() {
      return this.elapsed.asSeconds();
    },

    progressMax() {
      return this.duration.asSeconds();
    },
  },
};
</script>
